import React from "react";
import { ArrowIcon } from "../ArrowIcon.jsx";

import("./app-card.scss");

/**
 *
 * @param imageUrl: string, required
 * @param description: string
 * @param title: string,  required
 * @param link: () => void,  required
 * @param linkText: string, required
 * @returns {JSX.Element}
 * @constructor
 */
export const AppCard = ({ imageUrl, description, title, link, linkText }) => {
  return (
    <li className="app-card">
      <div className="app-card__container">
        <img
          className="app-card__logo"
          alt={`${title} logo`}
          src={imageUrl}
        />
        <p className="app-card__title">{title}</p>
      </div>
      <p className="app-card__description">
        {description || ""}
      </p>
      <a className="app-card__btn" href={link}>
        {linkText}
        <ArrowIcon />
      </a>
    </li>
  );
};