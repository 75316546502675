import { ABOUT_ROUTE } from "./routes";

export const FOOTER_LINKS = [
  {
    text: "About",
    external: false,
    link: ABOUT_ROUTE,
  },
  {
    text: "Contact",
    external: true,
    link: "https://www.innex.net/kontakt.html",
  },
  {
    text: "Imprint",
    external: true,
    link: "https://www.innex.net/impressum.html",
  },
  {
    text: "Confidentiality",
    external: true,
    link: "https://www.innex.net/datenschutz.html",
  },
  {
    text: "Conditions",
    external: true,
    link: "https://www.innex.net/files/bilder/unternehmen/Allgemeine_Vertragsbedingungen_fuer_Beratung_und_Unterstuetzung.pdf",
  },
];