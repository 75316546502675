import React from "react";
import { Header } from "../Header/Header.jsx";
import { Footer } from "../Footer/Footer.jsx";
import "./headerFooter.scss";

export const HeaderFooterLayout = ({ children, filled }) => {
  return (
    <div className={filled ? "layout layout_filled" : "layout"}>
      <Header title="myTools.innex.net" />
      {children}
      <Footer />
    </div>
  );
};