import React, { useMemo } from "react";
import "./about.scss";
import { BackBtn } from "../../components/BackBtn/BackBtn.jsx";
import { PACKAGES_LIST } from "../../constants/packages";

export const About = () => {

  const packages = useMemo(() => {
    return PACKAGES_LIST.map(({ name, gitLink, licenseLink }) => {
      return (
        <li className="about__card" key={name}>
          <p className="about__description">{name}</p>
          <a href={gitLink} className="about__link">Git</a>
          <a href={licenseLink} className="about__link">License</a>
        </li>
      );
    });
  }, []);

  return (
    <section className="about">
      <div className="about__container">
        <BackBtn title="About" />
        <h2 className="about__subtitle">Subheading</h2>
        <p className="about__description">
          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse
          pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt
          sint
          deserunt ut voluptate aute id deserunt nisi.
        </p>

        <h2 className="about__subtitle">Subheading</h2>
        <p className="about__description">
          Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse
          pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt
          sint
          deserunt ut voluptate aute id deserunt nisi.
        </p>
        <p className="about__description">Aliqua id fugiat nostrud irure ex duis ea quis id quis ad et. Sunt qui esse
          pariatur duis deserunt mollit dolore cillum minim tempor enim. Elit aute irure tempor cupidatat incididunt
          sint
          deserunt ut voluptate aute id deserunt nisi.
        </p>
        <h2 className="about__subtitle">Using packages</h2>
        <ul className="about__list">
          {packages}
        </ul>
      </div>
    </section>
  );
};