export const PACKAGES_LIST = [
  {
    name: "Axios",
    gitLink: "https://github.com/axios/axios",
    licenseLink: "https://github.com/axios/axios/blob/master/LICENSE",
  },
  {
    name: "Material UI",
    gitLink: "https://github.com/mui/material-ui",
    licenseLink: "https://github.com/mui/material-ui/blob/master/LICENSE",
  },
  {
    name: "JS Big Decimal",
    gitLink: "https://github.com/royNiladri/js-big-decimal",
    licenseLink: "https://github.com/royNiladri/js-big-decimal/blob/master/LICENSE",
  },
  {
    name: "Jspreadsheet CE",
    gitLink: "https://github.com/jspreadsheet/ce",
    licenseLink: "https://github.com/jspreadsheet/ce/blob/master/LICENSE",
  },
  {
    name: "Konva",
    gitLink: "https://github.com/konvajs/konva",
    licenseLink: "https://github.com/konvajs/konva/blob/master/LICENSE",
  },
  {
    name: "Moment",
    gitLink: "https://github.com/moment/moment",
    licenseLink: "https://github.com/moment/moment/blob/HEAD/LICENSE",
  },
  {
    name: "PDF.js",
    gitLink: "https://github.com/mozilla/pdf.js",
    licenseLink: "https://github.com/mozilla/pdf.js/blob/master/LICENSE",
  },
  {
    name: "React",
    gitLink: "https://github.com/facebook/react",
    licenseLink: "https://github.com/facebook/react/blob/main/LICENSE",
  },
  {
    name: "React-Konva",
    gitLink: "https://github.com/konvajs/react-konva",
    licenseLink: "https://github.com/konvajs/react-konva/blob/master/LICENSE",
  },
  {
    name: "Redux",
    gitLink: "https://github.com/reduxjs/redux",
    licenseLink: "https://github.com/reduxjs/redux/blob/master/LICENSE.md",
  },
  {
    name: "Redux-Saga",
    gitLink: "https://github.com/redux-saga/redux-saga/",
    licenseLink: "https://github.com/redux-saga/redux-saga/blob/master/LICENSE",
  },
  {
    name: "Reselect",
    gitLink: "https://github.com/reduxjs/reselect",
    licenseLink: "https://github.com/reduxjs/reselect/blob/master/LICENSE",
  },
  {
    name: "Universal cookie",
    gitLink: "https://github.com/reactivestack/cookies",
    licenseLink: "https://github.com/reactivestack/cookies/blob/master/LICENSE",
  },
  {
    name: "Use-image",
    gitLink: "https://github.com/konvajs/use-image",
    licenseLink: "https://github.com/konvajs/use-image/blob/master/LICENSE",
  },
  {
    name: "Cors",
    gitLink: "https://github.com/expressjs/cors",
    licenseLink: "https://github.com/expressjs/cors/blob/master/LICENSE",
  },
  {
    name: "Express",
    gitLink: "https://github.com/expressjs/express",
    licenseLink: "https://github.com/expressjs/express/blob/master/LICENSE",
  },
  {
    name: "Jsonwebtoken",
    gitLink: "https://github.com/auth0/node-jsonwebtoken",
    licenseLink: "https://github.com/auth0/node-jsonwebtoken/blob/master/LICENSE",
  },
  {
    name: "Mongoose",
    gitLink: "https://github.com/Automattic/mongoose",
    licenseLink: "https://github.com/Automattic/mongoose/blob/master/LICENSE.md",
  },
  {
    name: "Multer",
    gitLink: "https://github.com/expressjs/multer",
    licenseLink: "https://github.com/expressjs/multer/blob/master/LICENSE",
  },
  {
    name: "vue",
    gitLink: "https://github.com/vuejs/vue",
    licenseLink: "https://github.com/vuejs/vue/blob/dev/LICENSE",
  },
  {
    name: "vue-router",
    gitLink: "https://github.com/vuejs/vue-router",
    licenseLink: "https://github.com/vuejs/vue-router/blob/dev/LICENSE",
  },
  {
    name: "dotenv",
    gitLink: "https://github.com/motdotla/dotenv",
    licenseLink: "https://github.com/motdotla/dotenv/blob/master/LICENSE",
  },
  {
    name: "exceljs",
    gitLink: "https://github.com/exceljs/exceljs",
    licenseLink: "https://github.com/exceljs/exceljs/blob/master/LICENSE",
  },
  {
    name: "jstoxml",
    gitLink: "https://github.com/davidcalhoun/jstoxml",
    licenseLink: "https://github.com/davidcalhoun/jstoxml/blob/master/LICENSE.md",
  },
  {
    name: "multiparty",
    gitLink: "https://github.com/pillarjs/multiparty",
    licenseLink: "https://github.com/pillarjs/multiparty/blob/master/LICENSE",
  },
  {
    name: "xlsx",
    gitLink: "https://github.com/SheetJS/sheetjs",
    licenseLink: "https://github.com/SheetJS/sheetjs/blob/master/LICENSE",
  },
];