import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { Main } from "./view/Main/Main";
import { About } from "./view/About/About";
import { HeaderFooterLayout } from "./components/layout/HeaderFooter";
import { ABOUT_ROUTE, MAIN_ROUTE, OLD_MAIN_ROUTE } from "./constants/routes";
import "./App.scss";

export const App = () => {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route exact path={MAIN_ROUTE} element={
            <HeaderFooterLayout>
              <Main />
            </HeaderFooterLayout>
          } />
          <Route exact path={ABOUT_ROUTE} element={
            <HeaderFooterLayout filled>
              <About />
            </HeaderFooterLayout>
          } />
        </Routes>
      </div>
    </BrowserRouter>
  );
};