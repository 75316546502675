import React from "react";
import "./backBtn.scss";
import { Link } from "react-router-dom";
import { MAIN_ROUTE } from "../../constants/routes";

export const BackBtn = ({ title }) => {
  return (
    <Link to={MAIN_ROUTE} className="back-btn">
      <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5.78136 0.469364C5.85121 0.539032 5.90662 0.621796 5.94443 0.712913C5.98224 0.804031 6.0017 0.901713 6.0017 1.00036C6.0017 1.09901 5.98224 1.1967 5.94443 1.28781C5.90662 1.37893 5.85121 1.4617 5.78136 1.53136L1.81086 5.50036L5.78136 9.46936C5.8511 9.53909 5.90641 9.62188 5.94415 9.71299C5.98189 9.8041 6.00131 9.90175 6.00131 10.0004C6.00131 10.099 5.98189 10.1966 5.94415 10.2877C5.90641 10.3788 5.8511 10.4616 5.78136 10.5314C5.71163 10.6011 5.62885 10.6564 5.53774 10.6941C5.44663 10.7319 5.34898 10.7513 5.25036 10.7513C5.15175 10.7513 5.0541 10.7319 4.96299 10.6941C4.87188 10.6564 4.7891 10.6011 4.71936 10.5314L0.219364 6.03136C0.149519 5.9617 0.0941051 5.87893 0.0562954 5.78781C0.0184857 5.6967 -0.000976562 5.59901 -0.000976562 5.50036C-0.000976563 5.40171 0.0184857 5.30403 0.0562954 5.21291C0.0941051 5.1218 0.149519 5.03903 0.219364 4.96936L4.71936 0.469364C4.78903 0.399519 4.8718 0.344104 4.96291 0.306295C5.05403 0.268485 5.15171 0.249023 5.25036 0.249023C5.34902 0.249023 5.4467 0.268485 5.53781 0.306295C5.62893 0.344104 5.7117 0.399519 5.78136 0.469364Z"
              fill="#FAFAFA" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M0.75 5.5C0.75 5.30109 0.829018 5.11032 0.96967 4.96967C1.11032 4.82902 1.30109 4.75 1.5 4.75H17.25C17.4489 4.75 17.6397 4.82902 17.7803 4.96967C17.921 5.11032 18 5.30109 18 5.5C18 5.69891 17.921 5.88968 17.7803 6.03033C17.6397 6.17098 17.4489 6.25 17.25 6.25H1.5C1.30109 6.25 1.11032 6.17098 0.96967 6.03033C0.829018 5.88968 0.75 5.69891 0.75 5.5Z"
              fill="#FAFAFA" />
      </svg>
      <p className="back-btn__title">{title}</p>
    </Link>
  );
};