import React, { useMemo } from "react";
import { AppCard } from "../../components/AppCard/AppCard";
import { APPLICATIONS_LIST } from "../../constants/applications";

import ("./main.scss");

export const Main = () => {
  const cards = useMemo(() => {
    return APPLICATIONS_LIST.map((
      {
        title,
        imageUrl,
        linkText,
        description,
        link,
      },
      ) =>
        <AppCard
          key={title}
          title={title}
          link={link}
          imageUrl={imageUrl}
          linkText={linkText}
          description={description}
        />,
    );
  }, []);

  return (
    <div className="main">
      <ul className="main__cards">
        {cards}
      </ul>
    </div>
  );
};