import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";

import Logo from "../../assets/icons/logo.svg";

export const Header = ({ title }) => {
  return (
    <div className="header">
      <span className="header__logo">
        <Link to="/">
         <img src={Logo} alt="logo" />
        </Link>
      </span>
      {title && <span className="header__title">{title}</span>}
      <div className="header__item" />
    </div>
  );
};
