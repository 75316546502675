import reportDesignerLogo from "../assets/icons/apps/report-designer-logo.svg";
import ctoConverterLogo from "../assets/icons/apps/cto-converter-logo.svg";

const REPORT_DESIGNER_URL = process.env.REACT_APP_REPORT_DESIGNER_URL;
const CTO_TOOL_URL = process.env.REACT_APP_CTO_TOOL_URL;

export const APPLICATIONS_LIST = [
  {
    title: "REPORT SPEC DESIGNER",
    imageUrl: reportDesignerLogo,
    linkText: "Start",
    description: "Be faster and more efficient! Use the REPORT SPEC DESIGNER to boost your report design to the next level.",
    link: REPORT_DESIGNER_URL,
  },
  {
    title: "CTO-Converter",
    imageUrl: ctoConverterLogo,
    linkText: "Start",
    description: "Be faster and more efficient! Use the REPORT SPEC DESIGNER to boost your report design to the next level.",
    link: CTO_TOOL_URL,
  },
];
