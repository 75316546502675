import React, { useMemo } from "react";
import "./footer.scss";
import { FOOTER_LINKS } from "../../constants/footerLinkst";
import { Link, Router } from "react-router-dom";

export const Footer = () => {
  const navLinks = useMemo(() => {
    return FOOTER_LINKS.map(({ text, link, external }) => {
      if (external) {
        return (
          <li className="footer__list-item" key={text}>
            <a href={link} className="footer__link">{text}</a>
          </li>
        );
      } else {
        return (
          <li className="footer__list-item" key={text}>
            <Link to={link} className="footer__link">{text}</Link>
          </li>
        );
      }
    });
  }, []);

  return (
    <div className="footer">
      <nav className="footer__nav">
        <ul className="footer__list">
          {navLinks}
        </ul>
      </nav>
    </div>
  );
};
